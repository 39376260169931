import { FILE_RESOURCE_BASE_FRAGMENT } from '@/graphql/_Fragments/FileResource/Base';

export const DEAL_ENTITY_LIST_FRAGMENT = `
  fragment dealEntityListFragment on Deal {
    id
    uid
    schemaCode
    __typename
    name
    startTimestamp
    endTimestamp
    displayFileResource {
      ...fileResourceBaseFragment
    }
    exhibitor {
      uid
    }
    _isBookmarked(myUid: "%authUser%")
  }
  ${FILE_RESOURCE_BASE_FRAGMENT}
`;
